import * as React from 'react'
import Layout from '../components/Layout'
import TdLink from '../components/TdLink'

const NotFoundPage = () => (
  <Layout lang={{ slug: '', langKey: 'en' }} noStats={true}>
    <section className="position-relative pt-14 mt-n11 grow-container">
      <div className="container">
        <div className="row align-items-center text-md-left">
          <div className="col-xs-12">
            <h1 className="display-3 tw-bold text-center">Page not found</h1>
            <p className="mb-5 text-center text-muted"> You just hit a route that doesn&#39;t exist... </p>
            <div className="text-center">
              <TdLink className="btn btn-primary" to="/">
                Back to Home page
              </TdLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage